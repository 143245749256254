import { v4 as uuid } from 'uuid';

import { referrers, sources } from 'tracking/params';
import { trackEventFaro } from 'utils/faro';

export enum Endpoints {
  ACCEPT_INVITE = '/invite/confirm',
  ASSOCIATE = '/associate',
  CHECK_INVITE = '/invite/confirm',
  CREATE_INSTANCE = '/instances',
  CHECK_INSTANCE_SLUG = '/instances/checkUrl',
  CHECK_USER_SSO = '/check-user-sso',
  CHECK_ORGANISATION_SLUG = '/orgs/check-slug',
  CREATE_USER_FROM_INVITE = '/signup/invite',
  DASHBOARDS = '/dashboards',
  FORGOT_PASSWORD = '/forgotpw',
  GET_USER_PROFILE = '/profile',
  GET_INSTANCE = '/instances',
  GET_INSTANCES = '/instances',
  GET_ORGS = '/orgs',
  LOADING_INSTANCE = '/api/health',
  GET_ORG_SSO_PROVIDERS = '/invite/sso',
  GET_STACK_REGIONS = '/stack-regions',
  GET_CLOSEST_STACK_REGION = '/stack-regions/closest',
  RESEND_VALIDATION_EMAIL = '/signup/confirm/resend',
  SELECT_ORGANISATION = '/grafana-cloud/select-org',
  SIGN_IN = '/login',
  SIGN_OUT = '/logout',
  SIGN_UP = '/signup',
  START_TRIAL = '/grafana-cloud/trial/start',
  TRACK_TRIAL = '/grafana-cloud/start',
  VALIDATE_EMAIL_SHORT_KEY = '/signup/confirm-short-key',
  PLUGINS = '/plugins',
  UPDATE_USER_PROFILE = '/profile',
  GRAFANA_SSO_LOOKUP = '/login/grafana/sso/lookup',
  GRAFANA_SSO_LOGIN = '/login/grafana/sso/login',
}

interface ApiError {
  code: string;
  message: string;
}

const getErrorFromResponse = async (res: Response): Promise<ApiError> => {
  let error;

  try {
    error = await res.clone().json();
  } catch (err) {
    error = {
      code: res.statusText,
      message: await res.clone().text(),
    };
  }

  return error;
};

const request = async <R = any>(path: string, options: RequestInit = {}, unconstrictedFetch?: boolean): Promise<R> => {
  path = !unconstrictedFetch ? `/api${path}` : `${path}`;

  const requestId = uuid();
  const requestType = options.method || 'GET';

  trackEventFaro('api_call', {
    id: requestId,
    type: requestType,
    url: path,
  });

  const headers: Record<string, string> = {
    ...((options.headers as Record<string, string>) ?? {}),
    'X-Request-ID': requestId,
  };

  const { isource, osource } = sources;

  if (isource) {
    headers['X-ISource'] = isource;
  }

  if (osource) {
    headers['X-OSource'] = osource;
  }

  const { ireferrer, oreferrer } = referrers;

  if (ireferrer) {
    headers['X-IReferrer'] = ireferrer;
  }

  if (oreferrer) {
    headers['X-OReferrer'] = oreferrer;
  }

  return fetch(path, {
    ...options,
    headers,
  }).then(async (res) => {
    if (!res.ok) {
      const error = await getErrorFromResponse(res);

      trackEventFaro('api_call_failed', {
        id: requestId,
        type: requestType,
        url: path,
        ...error,
      });

      throw error;
    }

    trackEventFaro('api_call_success', {
      id: requestId,
      type: requestType,
      url: path,
    });

    return res.json();
  });
};

export const get = async <R = any>(path: string): Promise<R> => request<R>(path);

export const post = async <R = any>(path: string, data: Record<string, any>): Promise<R> =>
  request<R>(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

export const requestAndRetry = async <R = any>(path: string, retries = 3, options: RequestInit = {}): Promise<R> => {
  path = `/api${path}`;

  const requestId = uuid();
  const requestType = options.method || 'GET';

  trackEventFaro('api_call', {
    id: requestId,
    type: requestType,
    url: path,
  });

  const headers: Record<string, string> = {
    ...((options.headers as Record<string, string>) ?? {}),
    'X-Request-ID': requestId,
  };

  const { isource, osource } = sources;

  if (isource) {
    headers['X-ISource'] = isource;
  }

  if (osource) {
    headers['X-OSource'] = osource;
  }

  const { ireferrer, oreferrer } = referrers;

  if (ireferrer) {
    headers['X-IReferrer'] = ireferrer;
  }

  if (oreferrer) {
    headers['X-OReferrer'] = oreferrer;
  }

  return fetch(path, {
    ...options,
    headers,
  }).then(async (res) => {
    if (res.ok) {
      trackEventFaro('api_call_success', {
        id: requestId,
        type: requestType,
        url: path,
      });
      return res.json();
    } else if (retries > 0) {
      return requestAndRetry(path, retries - 1, options);
    } else {
      const error = await getErrorFromResponse(res);

      trackEventFaro('api_call_failed', {
        id: requestId,
        type: requestType,
        url: path,
        ...error,
      });

      throw error;
    }
  });
};
