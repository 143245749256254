import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import SmartInterval from 'utils/SmartInterval';

export const useCheckInstanceLoaded = async () => {
  const [instanceUrls, setInstanceUrls] = useState({ instanceUrl: '', returnUrl: '' });
  const [{ userData }, { getUser, setUser }] = useContext(UserContext);

  const [, { faro, trackEvent }] = useContext(TrackingContext);
  const [redirectTo, setRedirectTo] = useState<string>('');
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const instanceUrl = params?.get('instanceUrl');
  const returnUrl = params?.get('url');

  const checkInstanceReady = useCallback(async (): Promise<boolean> => {
    if (!!instanceUrls.instanceUrl) {
      return fetch(`${instanceUrls.instanceUrl}/api/health`)
        .then((response) => {
          return response.ok;
        })
        .catch((err) => {
          console.log('err', err.message);
          return false;
        });
    }
    return Promise.resolve(false);
  }, [instanceUrls.instanceUrl]);

  useEffectOnce(() => {
    if (!!instanceUrl && !!returnUrl) {
      setInstanceUrls({ instanceUrl, returnUrl });

      params.delete('instanceUrl');
      params.delete('url');
      history.replace({ search: params.toString() });
    }
  });

  useEffect(() => {
    let dataFetcher: any;

    if (!redirectTo) {
      let newUserData: any;

      // retry up to 500 times to check the instance every 1.5 seconds. If no success after 500 retries, redirect to portal
      let totalRetry = 500;
      let retries = 0;

      dataFetcher = new SmartInterval(async () => {
        retries++;
        const isReady = await checkInstanceReady();

        if (isReady || retries >= totalRetry) {
          newUserData = await getUser();
          await setUser(newUserData);

          dataFetcher.stop();

          const eventType = isReady ? 'user-hg-instance-ready' : 'user-hg-instance-reached-max-retries';
          const redirect = `${instanceUrls.instanceUrl}/set-redirect-and-login?url=${encodeURIComponent(
            instanceUrls.returnUrl
          )}`;

          faro.trackEvent(eventType, { redirect });
          trackEvent({
            name: eventType,
            userId: userData?.id,
            redirect,
          });

          setRedirectTo(redirect);
        }
      }, 1500);
      dataFetcher.start();
    }
    // Clearing the interval
    return () => {
      if (dataFetcher) {
        dataFetcher.stop();
      }
    };
  }, [checkInstanceReady, getUser, setUser, instanceUrls, redirectTo, faro, trackEvent, userData]);

  return redirectTo;
};
