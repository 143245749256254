import { ReactNode, useState } from 'react';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, get } from 'utils/api';

import { defaultStackRegionContextState, StackRegionContext } from './stackRegionContext';
import { GetStackRegionsFn, SetStackRegionsFn, StackRegion } from './types';

export interface StackRegionProviderProps {
  children: ReactNode;
}

export const StackRegionProvider = (props: StackRegionProviderProps) => {
  const [stackRegionState, setStackRegionState] = useState(defaultStackRegionContextState);

  const setStackRegions: SetStackRegionsFn = async (data) => {
    setStackRegionState((stackRegionState) => ({
      ...stackRegionState,
      stackRegionsData: data,
      isLoading: false,
    }));
  };

  const getStackRegions: GetStackRegionsFn = async () => {
    let [stackRegions, closestStackRegion] = await Promise.all([
        fetchStackRegions(),
        fetchClosestStackRegion(),
    ]);

    const regions = stackRegions?.items ?? [];

    if (closestStackRegion) {
      const closestRegionId = closestStackRegion.id;
      regions.sort((a, b) => {
        if (a.id === closestRegionId) {
            return -1;
        }
        if (b.id === closestRegionId) {
            return 1;
        }
        return 0;
      });
    }

    await setStackRegions(regions);
    return regions;
  };

  const fetchStackRegions = async(): Promise<{ items: StackRegion[] } | null> => {
    try {
      return await get<{ items: StackRegion[] }>(`${Endpoints.GET_STACK_REGIONS}?provider=aws`);
    } catch (err) {
      return null;
    }
  };

  const fetchClosestStackRegion = async(): Promise<StackRegion | null> => {
    try {
      return await get<StackRegion>(`${Endpoints.GET_CLOSEST_STACK_REGION}?provider=aws`);
    } catch (err) {
      return null;
    }
  };

  useEffectOnce(() => {
    getStackRegions();
  });

  return (
    <StackRegionContext.Provider value={[stackRegionState, { getStackRegions, setStackRegions }]}>
      {stackRegionState.isLoading || !props?.children ? null : props.children}
    </StackRegionContext.Provider>
  );
};
